@font-face {
  font-family: 'VisbyRoundCF Regular';
  src: url('../assets/fonts/VisbyRoundCF/VisbyRoundCF-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'VisbyRoundCF Medium';
  src: url('../assets/fonts/VisbyRoundCF/VisbyRoundCF-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'VisbyRoundCF SemiBold';
  src: url('../assets/fonts/VisbyRoundCF/VisbyRoundCF-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'VisbyRoundCF SemiBold Oblique';
  src: url('../assets/fonts/VisbyRoundCF/VisbyRoundCF-SemiBoldOblique.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'VisbyRoundCF Bold';
  src: url('../assets/fonts/VisbyRoundCF/VisbyRoundCF-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
